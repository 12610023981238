import { useEffect } from 'react'
import { navigate } from 'gatsby'

type PropertyEstimateProps = {
  params: {
    lead: string
  }
}

const PropertyEstimate = (props: PropertyEstimateProps) => {
  const { params } = props
  const { lead: leadUuid } = params
  useEffect(() => {
    // TODO: remove when dst product is not paused
    navigate(`/property-estimate/${leadUuid}`)
  })
  return null
}

export default PropertyEstimate
